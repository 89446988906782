const en_ES = {
  homeDiv: {
    home_title: "Instantánea Para Recibir Airdrops",
    home_snapshot_coin: "Moneda de Instantánea",
    home_add_wallet: "Agregar a la cartera",
    home_capital: "Capital",
    home_participate: "Participar",
    home_earnings: "Ganancias",
    home_withdraw: "Retirar",
    home_aggregate_Earnings_Pool: "Fondo de Ganancias Agregadas",
    home_you_send: "Tú envías",
    home_you_get: "Tú recibes",
    home_buy_vANY: "Comprar vANY",
  },
  homeJS: {
    home_snapshot: "Instantánea",
    home_incentives: "Incentivos",
    home_all: "Todo",
    home_toward: "Hacia",
    home_stop: "Detener",
    home_my: "Mi",
    home_status: "estado",
    home_token_added: "El token ha sido añadido a la cartera.",
    home_token_addition: "Fallo al añadir el token.",
    home_error_adding: "Error al añadir el token a la cartera.",
    home_error_adding_tokenYour:
      "Error al agregar el token. Tu cartera puede no soportarlo",
    home_please_install: "Por favor instala la cartera",
    home_please_switch_networks: "Por favor cambia de red",
    home_network_error: "Error de red, cambia de red y vuelve a intentarlo",
    home_stake_loading: "Cargando participación",
    home_snapshot_balance:
      "el saldo de la instantánea no es suficiente para obtener la recompensa, al menos:",
    home_you_are_blocked: "Estás bloqueado",
    home_loading: "cargando...",
    home_account_error: "error de cuenta",
    home_please_switch_chains: "Por favor cambia de cadena",
    home_please_network: "Por favor selecciona la red correcta",
    home_please_address: "Por favor conecta tu dirección",
    home_wallet_connect_deny: "conexión de cartera denegada",
    home_old_wallet_version: "versión antigua de la cartera",
    home_switch_chain_and_retry: "Cambia de cadena y vuelve a intentarlo",
    home_enter_number_tokens: "Ingresa la cantidad de tokens",
    home_please_select_token: "Por favor selecciona un token",
    home_copy_success: "copia exitosa",
    home_copy_error: "error de copia",
  },
  rulesDiv: {
    rules_title: "Reglas y Asignación de Intereses",
    rules_text:
      "Al invitar a tus amigos a entrar, si tus amigos invierten dinero en minería, serás recompensado con un 10 por ciento de los intereses de tus amigos, y estos intereses no afectarán",
    rules_text2:
      "Invierte dinero para comprometer monedas y obtener intereses, si el cambio cae a un cierto nivel, la plataforma liquidará los tokens",
    rules_invite_friends: "Invitar Amigos",
    rules_me: "YO",
    rules_layer_users: "Usuarios de Capa 1",
    rules_layer_users2: "Usuarios de Capa 2",
    rules_layer_users3: "Usuarios de Capa 3",
  },
  navMuenDiv: {
    nav_home: "Inicio",
    nav_rules: "Reglas",
    nav_language: "Idioma",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Hora de Finalización",
    card_APD: "APD",
    card_interest: "Interés",
    card_commissions: "Comisiones",
    card_capital: "Capital Acumulado",
    card_friends_interest: "Intereses de Amigos",
    card_supply: "Suministro",
    card_Resupply: "Resupply",
    card_redem: "Redimir",
    card_wiuhdraw: "Retirar",
    card_enter_amount: "Ingresa la cantidad",
    card_confirm: "confirmar",
    card_duration: "Duración",
    card_days: "días",
    card_minimum_withdrawal_amount: "Cantidad Mínima de Retiro",
    card_min_prarticion_amount: "Cantidad Mínima de Participación",
    card_current_Redeemable_Capital: "Capital Actualmente Reembolsable",
    card_Withdrawn_Commission: "Comisión Retirada",
    card_Withdrawn_Interest: "Interés Retirado",
    card_Withdrawn_Principa: "Principal Retirado",
  },
  cardJS: {
    card_loading: "cargando",
    card_input_empty: "La cantidad ingresada no puede estar vacía",
    card_success: "Éxito",
  },
  modeDiv: {
    mode_title: "Invitar Amigos",
    mode_save: "Guardar",
    mode_copy_success: "copia exitosa",
    mode_copy_error: "error de copia",
    mode_redem: "Redimir",
    mode_confirm: "confirmar",
    mode_input_empty: "La cantidad ingresada no puede estar vacía",
    mode_loading: "Cargando...",
    mode_success: "éxito",
    mode_failed: "fallido",
    mode_insufficient: "Fondos insuficientes para gas natural",
    mode_transaction: "Transacción fallida",
    mode_insufficient_balance: "Saldo insuficiente",
    mode_redeem_failed: "redención fallida",
    mode_rules: "Reglas",
    mode_inviting:
      "Al invitar a tus amigos a entrar, si tus amigos invierten dinero en minería, serás recompensado con un 10-30 por ciento de intereses",
    mode_supply: "Suministro",
    mode_user_denied: "Usuario denegó la transacción",
    mode_wiuhdraw: "Retirar",
    mode_: "Invitar Amigos",
    mode_wiuhdraw_text: "Retirar su interés o comisión",
    mode_supply_text: "Suministrar su principal",
    mode_redem_text: "Redimir su principal",
    mode_rules_text_1:
      "Ao convidar seus amigos para entrar, se seus amigos investirem dinheiro na mineração, você será recompensado com 10-30 por cento de juros",
    mode_rules_text_2:
      "APD é o retorno diário sobre os fundos, que flutuará ligeiramente pois o retorno será determinado pela utilização dos fundos.",
    mode_rules_text_3:
      "Seus juros, ou comissão, podem ser retirados a qualquer momento quando o Valor Mínimo de Retirada for atingido.",
    mode_rules_text_4:
      "Os fundos que você fornece, após atingir o prazo, podem ser mostrados no Capital Resgatável Atual, e você pode Resgatá-lo.",
    mode_rules_text_5:
      "O fim do projeto significa: o prazo do projeto atual, o fim do projeto não afeta seus fundos ou juros, retirada de comissões, o fim do projeto, um novo projeto será lançado, você pode se envolver em um novo projeto.",
    mode_Currentlyavailable: "Actualmente Disponible",
    mode_biejingTime: "hora de Londres",
    mode_startSnapshot: "Instantánea de inicio",
    mode_go_vAny: 'Ir vAny'
  },
  swapDiv: {
    swap_title: "Intercambio",
    swap_trade: "Comercia tokens al instante",
    swap_balance: "Saldo",
    swap_price: "Precio",
    swap_slippage: "Tolerancia al deslizamiento",
    swap_point: "Seleccionar punto deslizante",
    swap_SWAP: "INTERCAMBIAR",
  },
  swapJS: {
    swap_quantity: "Por favor, introduzca la cantidad correcta",
    swap_Successfullyswap: "Intercambio exitoso.",
    swap_failed: "Intercambio fallido.",
    swap_input_amount: "La cantidad de entrada no puede estar vacía.",
    swap_select_token: "Por favor, selecciona un token.",
    swap_insufficient_balance: "Tu saldo es insuficiente.",
  },
};
export default en_ES;
