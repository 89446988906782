import Web3 from "web3";
import Vue from "vue";
function test() {
  console.log("test");
  return "成功";
}
// 连接钱包
function ConnectWallet() {
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((res) => {
          this.$ls.set("WalletAddress", res);
          // 在这里可以执行任何你需要在账户变化时进行的操作
          window.ethereum.on("accountsChanged", (accounts) => {
            this.$ls.set("WalletAddress", accounts);
            window.location.reload();
            console.log("Account changed:", accounts[0]);
          });
          resolve(res);
        })
        .catch((error) => {
          this.$message.error("Please install your wallet");
          reject([]);
        });
    } else {
      this.$message.error("Please install your wallet");
      reject([]);
    }
  });
}
//监听网络切换
async function onCheckChain(params) {
  if (window.ethereum) {
    try {
      let network = await window.ethereum.request({
        method: "eth_chainId",
      }); //获得以太网链ID
      network = Number(network);
      console.log("当前网络id", network);
      this.$ls.set("chainID", network);
      window.ethereum.on("chainChanged", (isChainId) => {
        //监听链ID
        let ChainId = isChainId;
        console.log("未解码的十六进制id", isChainId);
        ChainId = Number(ChainId);
        console.log("监听当前网络id:", ChainId, window.ethereum.isTrust);
        this.$ls.set("chainID", ChainId);
        if (window.ethereum.isTrust) {
          //防止Trust 钱包刷新会触发两次
          this.$message.success(
            "Chain changed successfully,The current network is:" + ChainId
          );
          window.location.reload();
        } else {
          console.log("切换链提示:", ChainId);
          this.$message.success(
            "Chain changed successfully,The current network is:" + ChainId
          );
          window.location.reload();
        }
      });
    } catch (e) {}
  }
}
// 连接web3实例
function ConnectWbe3() {
  return new Promise((resolve, reject) => {
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (web3) {
      web3 = new Web3(window.web3.currentProvider);
    } else {
      // 如果没有metamask，则使用infura
      const provider = new Web3.providers.HttpProvider("YOUR_INFURA_ENDPOINT");
      web3 = new Web3(provider);
    }
    resolve(web3);
  });
}
//数字千分位格式化
function formatNumber(number) {
  // 使用正则表达式将数字格式化为千分位
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//时间戳转换
function timestampToTime(timestamp) {
  timestamp = timestamp ? timestamp * 1000 : null;
  let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
//判断是否为科学计数法
function isScientificNotation(str) {
  // 科学计数法的正则表达式
  const scientificPattern = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/;

  // 使用正则表达式进行匹配判断
  return scientificPattern.test(str);
}
//将科学计数法的数字转换为正常的小数
function convertScientificToNormal(scientificNum) {
   return Number(scientificNum).toFixed(20).replace(/\.?0+$/, '');
}
/**
 *
 * @param {*} num 传入转换的数字
 * valueToRound 是否整数末尾加.00
 * @returns //返回俩位数字符串数字
 */
function toFixedTwo(num, valueToRound) {
  if (isScientificNotation(num)) {
    num = convertScientificToNormal(num);
    console.log('是科学计数法',convertScientificToNormal(num));
  } else {
    num = num.toString();
  }
  let parts = num.split(".");
  if (parts.length > 1) {
    if (num.toString().length < 4) {
      for (let i = 0; i < 4 - num.toString().length; i++) {
        num = num + "0";
      }
    } else {
      parts[1] = parts[1].slice(0, 2);
      num = parts[0] + "." + parts[1];
    }
  } else {
    num = valueToRound ? num + ".00" : num;
  }
  return num;
}
/**
 *
 * @param {*} num 传入转换的带小数的数字
 * 保留N位小数
 * valueToRound 是否整数末尾加.00
 * @returns //返回俩位数字符串数字
 */
function toFixedFloat(num, n) {
  num = num.toString();
  let parts = num.split(".");
  if (parts.length > 1) {
    if (parts[1].length < n) {
      parts[1] = parts[1].slice(0, parts[1].length);
    } else {
      parts[1] = parts[1].slice(0, n);
    }
    num = parts[0] + "." + parts[1];
  }
  return num;
}
export {
  test,
  formatNumber,
  ConnectWallet,
  ConnectWbe3,
  onCheckChain,
  timestampToTime,
  toFixedTwo,
  toFixedFloat,
};
