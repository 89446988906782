//法语
const fr_FR = {
  homeDiv: {
    home_title: "Instantané pour recevoir des airdrops",
    home_snapshot_coin: "Pièce Instantanée",
    home_add_wallet: "Ajouter au portefeuille",
    home_capital: "Capital",
    home_participate: "Participer",
    home_earnings: "Gains",
    home_withdraw: "Retirer",
    home_aggregate_Earnings_Pool: "Piscine des gains agrégés",
    home_you_send: "Vous envoyez",
    home_you_get: "Vous recevez",
    home_buy_vANY: "Acheter vANY",
  },
  homeJS: {
    home_snapshot: "Instantané",
    home_incentives: "Incitations",
    home_all: "Tout",
    home_toward: "Vers",
    home_stop: "Arrêter",
    home_my: "Mon",
    home_status: "statut",
    home_token_added: 'Le jeton a été ajouté au portefeuille.',
    home_token_addition: "Échec de l'ajout du jeton.",
    home_error_adding: "Erreur lors de l'ajout du jeton au portefeuille.",
    home_error_adding_tokenYour:
      "Erreur d'ajout de jeton. Votre portefeuille peut ne pas le supporter",
    home_please_install: "Veuillez installer le portefeuille",
    home_please_switch_networks: "Veuillez changer de réseau",
    home_network_error: "Erreur réseau, changez de réseau et réessayez",
    home_network_errorthis: 'Le réseau est incorrect. L\'identifiant réseau correct est',
    home_network_current: 'Le réseau actuel est',
    home_stake_loading: "Chargement de la mise",
    home_snapshot_balance:
      "le solde de l'instantané n'est pas suffisant pour obtenir la récompense, au moins :",
    home_you_are_blocked: "Vous êtes bloqué",
    home_loading: "chargement...",
    home_account_error: "erreur de compte",
    home_please_switch_chains: "Veuillez changer de chaîne",
    home_please_network: "Veuillez sélectionner le bon réseau",
    home_please_address: "Veuillez connecter votre adresse",
    home_wallet_connect_deny: "connexion au portefeuille refusée",
    home_old_wallet_version: "ancienne version du portefeuille",
    home_switch_chain_and_retry: "Changez de chaîne et réessayez",
    home_enter_number_tokens: "Entrez le nombre de jetons",
    home_please_select_token: "Veuillez sélectionner un jeton",
    home_copy_success: "copie réussie",
    home_copy_error: "erreur de copie",
  },
  rulesDiv: {
    rules_title: "Règles et répartition des intérêts",
    rules_text:
      "En invitant vos amis à entrer, si vos amis investissent de l'argent dans le minage, vous serez récompensé de 10 pour cent des intérêts de vos amis, et ces intérêts n'affecteront pas",
    rules_text2:
      "Investir de l'argent pour mettre en gage des pièces et obtenir des intérêts, si le changement tombe à un certain niveau, la plateforme liquidera les jetons",
    rules_invite_friends: "Inviter des amis",
    rules_me: "MOI",
    rules_layer_users: "Utilisateurs de niveau 1",
    rules_layer_users2: "Utilisateurs de niveau 2",
    rules_layer_users3: "Utilisateurs de niveau 3",
  },
  navMuenDiv: {
    nav_home: "Accueil",
    nav_rules: "Règles",
    nav_language: "Langue",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Heure de fin",
    card_APD: "APD",
    card_interest: "Intérêt",
    card_commissions: "Commissions",
    card_capital: "Capital accumulé",
    card_friends_interest: "Intérêt des amis",
    card_supply: "Fournir",
    card_Resupply:'Resupply',
    card_redem: "Racheter",
    card_wiuhdraw: "Retirer",
    card_enter_amount: "Entrer le montant",
    card_confirm: "confirmer",
    card_duration: "Durée",
    card_days: "jours",
    card_minimum_withdrawal_amount: "Montant de retrait minimum",
    card_min_prarticion_amount: "Montant minimum de participation",
    card_current_Redeemable_Capital: "Capital actuellement remboursable",
    card_Withdrawn_Commission: "Commission retirée",
    card_Withdrawn_Interest: "Intérêt retiré",
    card_Withdrawn_Principa: "Principal retiré",
  },
  cardJS: {
    card_loading: "chargement",
    card_input_empty: "Le montant saisi ne peut pas être vide",
    card_success: "Succès",
  },
  modeDiv: {
    mode_title: "Inviter des amis",
    mode_save: "Enregistrer",
    mode_copy_success: "copie réussie",
    mode_copy_error: "erreur de copie",
    mode_redem: "Racheter",
    mode_confirm: "confirmer",
    mode_input_empty: "Le montant saisi ne peut pas être vide",
    mode_loading: "Chargement...",
    mode_success: "succès",
    mode_failed: "échec",
    mode_insufficient: "Financement insuffisant pour le gaz naturel",
    mode_transaction: "Transaction échouée",
    mode_insufficient_balance: "Solde insuffisant",
    mode_redeem_failed: "rachat échoué",
    mode_rules: "Règles",
    mode_inviting:
      "En invitant vos amis à entrer, si vos amis investissent de l'argent dans le minage, vous serez récompensé de 10-30 pour cent des intérêts",
    mode_supply: "Fournir",
    mode_user_denied: "L'utilisateur a refusé la transaction",
    mode_wiuhdraw: "Retirer",
    mode_: "Inviter des amis",
    mode_wiuhdraw_text: 'Retirez vos intérêts ou commissions',
    mode_supply_text: "Fournissez votre capital",
    mode_redem_text: "Rachetez votre capital",
    mode_rules_text_1: "En invitant vos amis à entrer, si vos amis investissent de l'argent dans le minage, vous serez récompensé par un intérêt de 10-30 pour cent",
    mode_rules_text_2: "L'APD est le rendement quotidien des fonds, qui fluctuera légèrement car le rendement sera déterminé par l'utilisation des fonds.",
    mode_rules_text_3: "Votre intérêt, ou commission, peut être retiré à tout moment lorsque le montant minimum de retrait est atteint.",
    mode_rules_text_4: "Les fonds que vous fournissez, une fois le terme atteint, peuvent être affichés dans le capital rachetable actuel, et vous pouvez les racheter.",
    mode_rules_text_5: "La fin du projet signifie : la date limite du projet en cours, la fin du projet n'affecte pas vos fonds ou intérêts, les retraits de commissions, la fin du projet, un nouveau projet sera lancé, vous pouvez vous engager dans un nouveau projet."
    ,mode_Currentlyavailable:'Actuellement disponible',
    mode_biejingTime: "heure de Londres",
    mode_startSnapshot: "Démarrer la capture instantanée",
    mode_go_vAny: 'Aller vAny'
  },
  swapDiv: {
    swap_title: "Échange",
    swap_trade: "Échangez des jetons instantanément",
    swap_balance: "Solde",
    swap_price: "Prix",
    swap_slippage: "Tolérance au glissement",
    swap_point: "Sélectionnez le point de glissement",
    swap_SWAP: "ÉCHANGER",
  },
  swapJS: {
    swap_quantity: "Veuillez saisir la quantité correcte",
    swap_Successfullyswap: 'Échange effectué avec succès.',
    swap_failed: "Échec de l'échange.",
    swap_input_amount: 'Le montant saisi ne peut pas être vide.',
    swap_select_token: 'Veuillez sélectionner un jeton.',
    swap_insufficient_balance: 'Votre solde est insuffisant.'
  },
};
export default fr_FR;
