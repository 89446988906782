import Vue from "vue";
import VueClipboard from "vue-clipboard2";
Vue.directive("format", {
  bind(el, binding) {
    el.innerHTML = formatNumber(binding.value);
  },
  update(el, binding) {
    el.innerHTML = formatNumber(binding.value);
  },
});
function formatNumber(number) {
  // 使用正则表达式将数字格式化为千分位
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const loadingItms = {
  bind(el, binding) {
    const image = require("../assets/anyswap.gif");
    const spinner = document.createElement("div");
    spinner.className = "loading-spinner";
    spinner.style.width = "100%";
    spinner.style.height = "101%";
    spinner.style.zIndex=2025;
    spinner.style.background = "#181818";
    spinner.style.position = "fixed";
    spinner.style.opacity = 0.8;
    spinner.style.top = "0";
    spinner.style.left = "0";
    // spinner.style.transform = "translate(-50%, -50%)";
    spinner.style.display = "none";
    const ContentBox = document.createElement("div");
    ContentBox.className = "content-box";

    const spinnerContent = document.createElement("div");
    spinnerContent.className = "spinner-content";
    spinnerContent.style.backgroundImage = `url(${image})`;
    spinnerContent.style.width = "17rem";
    spinnerContent.style.height = "4rem";
    const ContentText = document.createElement("div");
    ContentText.className = "content-text";

    // ContentText.innerText = "loading…";
    const spinnerStyle = document.createElement("style");
    spinnerStyle.type = "text/css";
    spinnerStyle.innerHTML = `
          .content-box {
            position :absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            text-align:center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .spinner-content{
                background-size: cover;
                background-size: 100% 100%;
            }
            .content-text{
                margin-top:0.2rem;
                font-size:0.2rem;
               
               color: #C8C8C8;
            }
          }
        `;
    document.head.appendChild(spinnerStyle);

    el.style.position = "relative";
    el.spinner = spinner;
    el.appendChild(spinner);
    spinner.appendChild(ContentBox);
    ContentBox.appendChild(spinnerContent);
    ContentBox.appendChild(ContentText);
    if (binding.value) {
      spinner.style.display = "block";
    }
  },
  update(el, binding) {
    if (binding.value) {
      el.spinner.style.display = "block";
    } else {
      el.spinner.style.display = "none";
    }
  },
  unbind(el) {
    if (el.spinner && el.spinner.parentNode) {
      el.spinner.parentNode.removeChild(el.spinner);
    }
  },
};//加载
Vue.directive("loadingItms", loadingItms);
Vue.directive("clipboard", VueClipboard);
