//韩语
const ko_KR = {
  homeDiv: {
    home_title: "에어드롭을 받기 위한 스냅샷",
    home_snapshot_coin: "스냅샷 코인",
    home_add_wallet: "지갑에 추가",
    home_capital: "자본",
    home_participate: "참여",
    home_earnings: "수익",
    home_withdraw: "출금",
    home_aggregate_Earnings_Pool: "총수익 풀",
    home_you_send: "보내는 금액",
    home_you_get: "받는 금액",
    home_buy_vANY: "vANY 구매",
  },
  homeJS: {
    home_snapshot: "스냅샷",
    home_incentives: "인센티브",
    home_all: "모두",
    home_toward: "향하다",
    home_stop: "중지",
    home_my: "나의",
    home_status: "상태",
    home_token_added: '지갑에 토큰이 추가되었습니다.',
    home_token_addition: '토큰 추가에 실패했습니다.',
    home_error_adding: '지갑에 토큰을 추가하는 중 오류가 발생했습니다.',
    home_error_adding_tokenYour:
      "토큰 추가 오류. 지갑이 이를 지원하지 않을 수 있습니다",
    home_please_install: "지갑을 설치해 주세요",
    home_please_switch_networks: "네트워크를 변경해 주세요",
    home_network_error:
      "네트워크 오류, 네트워크를 변경한 후 다시 시도해 주세요",
      home_network_errorthis: '네트워크가 올바르지 않습니다. 정확한 네트워크 ID는',
      home_network_current: '현재 네트워크는',
    home_stake_loading: "스테이크 로딩 중",
    home_snapshot_balance:
      "보상을 받기 위한 스냅샷 잔액이 충분하지 않습니다, 최소:",
    home_you_are_blocked: "차단되었습니다",
    home_loading: "로딩 중...",
    home_account_error: "계정 오류",
    home_please_switch_chains: "체인을 변경해 주세요",
    home_please_network: "올바른 네트워크를 선택해 주세요",
    home_please_address: "주소를 연결해 주세요",
    home_wallet_connect_deny: "지갑 연결 거부됨",
    home_old_wallet_version: "낡은 지갑 버전",
    home_switch_chain_and_retry: "체인을 변경하고 다시 시도해 주세요",
    home_enter_number_tokens: "토큰 수를 입력해 주세요",
    home_please_select_token: "토큰을 선택해 주세요",
    home_copy_success: "복사 성공",
    home_copy_error: "복사 오류",
  },
  rulesDiv: {
    rules_title: "규칙 및 이자 할당",
    rules_text:
      "친구들을 초대하여 입장시키면, 친구들이 채굴에 돈을 투자할 경우, 당신은 친구들의 이자에서 10퍼센트를 보상받으며, 이 이자는 영향을 미치지 않습니다",
    rules_text2:
      "코인을 담보로 투자하여 이자를 얻을 수 있습니다. 가격이 일정 수준으로 떨어지면, 플랫폼이 토큰을 청산할 것입니다",
    rules_invite_friends: "친구 초대",
    rules_me: "나",
    rules_layer_users: "레이어 1 사용자",
    rules_layer_users2: "레이어 2 사용자",
    rules_layer_users3: "레이어 3 사용자",
  },
  navMuenDiv: {
    nav_home: "홈",
    nav_rules: "규칙",
    nav_language: "언어",
  },
  cardDiv: {
    card_compound: "컴파운드 V3",
    card_end_time: "종료 시간",
    card_APD: "APD",
    card_interest: "이자",
    card_commissions: "수수료",
    card_capital: "누적 자본",
    card_friends_interest: "친구 이자",
    card_supply: "공급",
    card_Resupply:'Resupply',
    card_redem: "상환",
    card_wiuhdraw: "출금",
    card_enter_amount: "금액 입력",
    card_confirm: "확인",
    card_duration: "기간",
    card_days: "일",
    card_minimum_withdrawal_amount: "최소 인출 금액",
    card_min_prarticion_amount: "최소 참여 금액",
    card_current_Redeemable_Capital: "현재 환급 가능 자본",
    card_Withdrawn_Commission: "인출된 수수료",
    card_Withdrawn_Interest: "인출된 이자",
    card_Withdrawn_Principa: "인출된 원금",
  },
  cardJS: {
    card_loading: "로딩 중",
    card_input_empty: "입력 금액을 비울 수 없습니다",
    card_success: "성공",
  },
  modeDiv: {
    mode_title: "친구 초대",
    mode_save: "저장",
    mode_copy_success: "복사 성공",
    mode_copy_error: "복사 오류",
    mode_redem: "상환",
    mode_confirm: "확인",
    mode_input_empty: "입력 금액을 비울 수 없습니다",
    mode_loading: "로딩 중...",
    mode_success: "성공",
    mode_failed: "실패",
    mode_insufficient: "천연가스 자금 부족",
    mode_transaction: "거래 실패",
    mode_insufficient_balance: "잔액 부족",
    mode_redeem_failed: "상환 실패",
    mode_rules: "규칙",
    mode_inviting:
      "친구들을 초대하여 입장시키면, 친구들이 채굴에 돈을 투자할 경우, 당신은 10-30퍼센트의 이자를 보상받습니다",
    mode_supply: "공급",
    mode_user_denied: "사용자가 거래를 거부했습니다",
    mode_wiuhdraw: "출금",
    mode_: "친구 초대",
    mode_wiuhdraw_text: '이자나 수수료를 인출하세요',
    mode_supply_text: "원금을 공급하세요",
    mode_redem_text: "원금을 환불하세요",
    mode_rules_text_1: "친구를 초대하여 입장시키면, 친구가 채굴에 자금을 투입하면, 자금 이용의 10-30% 이자를 받게 됩니다.",
    mode_rules_text_2: "APD는 자금의 일일 수익률로, 자금 이용에 따라 약간 변동할 수 있습니다.",
    mode_rules_text_3: "최소 출금 금액에 도달하면 언제든지 이자 또는 수수료를 인출할 수 있습니다.",
    mode_rules_text_4: "제공하는 자금은 기한에 도달한 후 현재 교환 가능 자본에 표시되며 교환할 수 있습니다.",
    mode_rules_text_5: "프로젝트의 종료는 현재 프로젝트의 마감일을 의미합니다. 프로젝트의 종료는 자금이나 이자, 수수료 인출에 영향을 주지 않습니다. 프로젝트 종료 후 새로운 프로젝트가 출시되며 새로운 프로젝트에 참여할 수 있습니다.",
    mode_Currentlyavailable:'현재 사용 가능합니다',
    mode_biejingTime: "런던 시간",
    mode_startSnapshot: "스냅숏 시작",
    mode_go_vAny: '가다 vAny'
  },
  swapDiv: {
    swap_title: "스왑",
    swap_trade: "즉시 토큰 거래",
    swap_balance: "잔고",
    swap_price: "가격",
    swap_slippage: "슬리피지 허용",
    swap_point: "슬라이딩 지점 선택",
    swap_SWAP: "스왑",
  },
  swapJS: {
    swap_quantity: "올바른 양을 입력하십시오",
    swap_Successfullyswap: '성공적으로 스왑하였습니다.',
    swap_failed: '스왑 실패했습니다.',
    swap_input_amount: '입력 금액을 비워둘 수 없습니다.',
    swap_select_token: '토큰을 선택해 주세요.',
    swap_insufficient_balance: '잔액이 부족합니다.'
  },
};
export default ko_KR;
