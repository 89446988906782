const en_TEQ = {
  homeDiv: {
    home_title: "Airdrop Almak İçin Anlık Görüntü",
    home_snapshot_coin: "Anlık Görüntü Coini",
    home_add_wallet: "Cüzdana ekle",
    home_capital: "Sermaye",
    home_participate: "Katıl",
    home_earnings: "Kazançlar",
    home_withdraw: "Çek",
    home_aggregate_Earnings_Pool: "Toplam Kazanç Havuzu",
    home_you_send: "Gönderdiğiniz",
    home_you_get: "Aldığınız",
    home_buy_vANY: "vANY Satın Al",
  },
  homeJS: {
    home_snapshot: "Anlık Görüntü",
    home_incentives: "Teşvikler",
    home_all: "Hepsi",
    home_toward: "Yönünde",
    home_stop: "Durdur",
    home_my: "Benim",
    home_status: "durum",
    home_token_added: "Jeton cüzdana eklendi.",
    home_token_addition: "Jeton ekleme başarısız.",
    home_error_adding: "Jetonu cüzdana eklerken hata oluştu.",
    home_error_adding_tokenYour:
      "Token ekleme hatası. Cüzdanınız bunu desteklemeyebilir",
    home_please_install: "Lütfen cüzdanı yükleyin",
    home_please_switch_networks: "Lütfen ağları değiştirin",
    home_network_error: "Ağ hatası, ağı değiştirin ve tekrar deneyin",
    home_stake_loading: "Stake yükleniyor",
    home_snapshot_balance:
      "ödül almak için anlık görüntü bakiyesi yeterli değil, en az:",
    home_you_are_blocked: "Engellendiniz",
    home_loading: "yükleniyor...",
    home_account_error: "hesap hatası",
    home_please_switch_chains: "Lütfen zincirleri değiştirin",
    home_please_network: "Lütfen doğru ağı seçin",
    home_please_address: "Lütfen adresinizi bağlayın",
    home_wallet_connect_deny: "cüzdan bağlantısı reddedildi",
    home_old_wallet_version: "eski cüzdan versiyonu",
    home_switch_chain_and_retry: "Zinciri değiştirin ve tekrar deneyin",
    home_enter_number_tokens: "Token sayısını girin",
    home_please_select_token: "Lütfen bir token seçin",
    home_copy_success: "kopyalama başarılı",
    home_copy_error: "kopyalama hatası",
  },
  rulesDiv: {
    rules_title: "Kurallar ve Faiz Dağılımı",
    rules_text:
      "Arkadaşlarınızı davet ederek, eğer arkadaşlarınız madenciliğe para yatırırsa, arkadaşlarınızın faizinden yüzde 10 ödüllendirileceksiniz ve bu faiz etkilenmeyecektir",
    rules_text2:
      "Parayı yatırarak coinleri taahhüt edin ve faiz kazanın, eğer değişiklik belirli bir seviyeye düşerse, platform tokenleri tasfiye edecektir",
    rules_invite_friends: "Arkadaşları Davet Et",
    rules_me: "BEN",
    rules_layer_users: "1. Katman kullanıcıları",
    rules_layer_users2: "2. Katman kullanıcıları",
    rules_layer_users3: "3. Katman kullanıcıları",
  },
  navMuenDiv: {
    nav_home: "Ana Sayfa",
    nav_rules: "Kurallar",
    nav_language: "Dil",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Bitiş Zamanı",
    card_APD: "APD",
    card_interest: "Faiz",
    card_commissions: "Komisyonlar",
    card_capital: "Birikmiş Sermaye",
    card_friends_interest: "Arkadaşların Faizi",
    card_supply: "Arz",
    card_Resupply: "Resupply",
    card_redem: "Geri Al",
    card_wiuhdraw: "Çek",
    card_enter_amount: "Miktarı girin",
    card_confirm: "onayla",
    card_duration: "Süre",
    card_days: "gün",
    card_minimum_withdrawal_amount: "Asgari Çekim Tutarı",
    card_min_prarticion_amount: "Asgari Katılım Tutarı",
    card_current_Redeemable_Capital: "Şu Anki Geri Ödenebilir Sermaye",
    card_Withdrawn_Commission: "Çekilen Komisyon",
    card_Withdrawn_Interest: "Çekilen Faiz",
    card_Withdrawn_Principa: "Çekilen Temel",
  },
  cardJS: {
    card_loading: "yükleniyor",
    card_input_empty: "Girdi miktarı boş olamaz",
    card_success: "Başarılı",
  },
  modeDiv: {
    mode_title: "Arkadaşları Davet Et",
    mode_save: "Kaydet",
    mode_copy_success: "kopyalama başarılı",
    mode_copy_error: "kopyalama hatası",
    mode_redem: "Geri Al",
    mode_confirm: "onayla",
    mode_input_empty: "Girdi miktarı boş olamaz",
    mode_loading: "Yükleniyor...",
    mode_success: "başarılı",
    mode_failed: "başarısız",
    mode_insufficient: "Doğal gaz için yetersiz fon",
    mode_transaction: "İşlem başarısız oldu",
    mode_insufficient_balance: "Yetersiz bakiye",
    mode_redeem_failed: "geri alma başarısız oldu",
    mode_rules: "Kurallar",
    mode_inviting:
      "Arkadaşlarınızı davet ederek, eğer arkadaşlarınız madenciliğe para yatırırsa, yüzde 10-30 faiz ödüllendirileceksiniz",
    mode_supply: "Arz",
    mode_user_denied: "Kullanıcı işlemi reddetti",
    mode_wiuhdraw: "Çek",
    mode_: "Arkadaşları Davet Et",
    mode_wiuhdraw_text: "Faiz veya komisyonunuzu çekin",
    mode_supply_text: "Ana meblağınızı sağlayın",
    mode_redem_text: "Ana meblağınızı kullanın",
    mode_rules_text_1:
      "Arkadaşlarınızı davet ederek, arkadaşlarınız madenciliğe para koyarsa, size yüzde 10-30 faizle ödüllendirilirsiniz",
    mode_rules_text_2:
      "APD, fonlara günlük geri dönüşüm sağlar, dönüşüm fonların kullanımına göre belirleneceği için hafifçe dalgalanacaktır.",
    mode_rules_text_3:
      "Faiziniz veya komisyonunuz, Minimum Çekim Tutarı'na ulaşıldığında her zaman çekilebilir.",
    mode_rules_text_4:
      "Sağladığınız fonlar, süreye ulaştıktan sonra Mevcut Geri Alınabilir Sermaye'de gösterilebilir ve Siz değiştirebilirsiniz.",
    mode_rules_text_5:
      "Projenin sonu demektir: mevcut projenin son teslim tarihi, proje sonu fonlarınızı veya faizlerinizi etkilemez, komisyon çekimi, proje sonu, yeni bir proje yayınlanacak, yeni bir projede tekrar katılabilirsiniz.",
    mode_Currentlyavailable: "Şu Anda Kullanılabilir",
    mode_biejingTime: "Londra saati",
    mode_startSnapshot: "Başlangıç anı",
    mode_go_vAny: 'Gitmek vAny'
  },
  swapDiv: {
    swap_title: "Değiştir",
    swap_trade: "Anında token ticareti",
    swap_balance: "Bakiye",
    swap_price: "Fiyat",
    swap_slippage: "Kayma Toleransı",
    swap_point: "Kaydırma noktasını seçin",
    swap_SWAP: "DEĞİŞTİR",
  },
  swapJS: {
    swap_quantity: "Lütfen doğru miktarı girin",
    swap_Successfullyswap: "Başarılı bir swap.",
    swap_failed: "Swap başarısız.",
    swap_input_amount: "Giriş miktarı boş olamaz.",
    swap_select_token: "Lütfen bir token seçin.",
    swap_insufficient_balance: "Bakiyeniz yetersiz.",
  },
};
export default en_TEQ;
