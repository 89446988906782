//日语
const ja_JP = {
  homeDiv: {
    home_title: "エアドロップを受け取るためのスナップショット",
    home_snapshot_coin: "スナップショットコイン",
    home_add_wallet: "ウォレットに追加",
    home_capital: "資本",
    home_participate: "参加",
    home_earnings: "収益",
    home_withdraw: "引き出し",
    home_aggregate_Earnings_Pool: "総収益プール",
    home_you_send: "あなたが送る",
    home_you_get: "あなたが得る",
    home_buy_vANY: "vANYを購入",
  },
  homeJS: {
    home_snapshot: "スナップショット",
    home_incentives: "インセンティブ",
    home_all: "全て",
    home_toward: "向かう",
    home_stop: "停止",
    home_my: "私の",
    home_status: "ステータス",
    home_token_added: "ウォレットにトークンが追加されました。",
    home_token_addition: "トークンの追加に失敗しました。",
    home_error_adding:
      "ウォレットにトークンを追加する際にエラーが発生しました。",
    home_error_adding_tokenYour:
      "トークンの追加エラー。ウォレットが対応していない可能性があります",
    home_please_install: "ウォレットをインストールしてください",
    home_please_switch_networks: "ネットワークを切り替えてください",
    home_network_error:
      "ネットワークエラー、ネットワークを切り替えてもう一度お試しください",
    home_network_errorthis:
      "ネットワークが正しくありません。正しいネットワークIDは",
    home_network_current: "現在のネットワークは",
    home_stake_loading: "ステークの読み込み中",
    home_snapshot_balance:
      "報酬を受け取るためのスナップショット残高が不足しています, 少なくとも:",
    home_you_are_blocked: "あなたはブロックされています",
    home_loading: "読み込み中...",
    home_account_error: "アカウントエラー",
    home_please_switch_chains: "チェーンを切り替えてください",
    home_please_network: "正しいネットワークを選択してください",
    home_please_address: "アドレスを接続してください",
    home_wallet_connect_deny: "ウォレット接続が拒否されました",
    home_old_wallet_version: "古いウォレットバージョン",
    home_switch_chain_and_retry: "チェーンを切り替えて再試行してください",
    home_enter_number_tokens: "トークンの数を入力してください",
    home_please_select_token: "トークンを選択してください",
    home_copy_success: "コピー成功",
    home_copy_error: "コピーエラー",
  },
  rulesDiv: {
    rules_title: "ルールと利息配分",
    rules_text:
      "友達を招待してエントリーさせると、友達がマイニングに資金を投入した場合、あなたは友達から10％の利息を受け取り、この利息は影響しません",
    rules_text2:
      "コインを担保にして投資し、利息を得ることができます。価格が一定のレベルに下がると、プラットフォームがトークンを清算します",
    rules_invite_friends: "友達を招待する",
    rules_me: "私",
    rules_layer_users: "レイヤー1のユーザー",
    rules_layer_users2: "レイヤー2のユーザー",
    rules_layer_users3: "レイヤー3のユーザー",
  },
  navMuenDiv: {
    nav_home: "ホーム",
    nav_rules: "ルール",
    nav_language: "言語",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "終了時間",
    card_APD: "APD",
    card_interest: "利息",
    card_commissions: "手数料",
    card_capital: "蓄積資本",
    card_friends_interest: "友達の利息",
    card_supply: "リサプライ",
    card_Resupply: "Resupply",
    card_redem: "リディーム",
    card_wiuhdraw: "ウィズドロー",
    card_enter_amount: "金額を入力",
    card_confirm: "確認",
    card_duration: "期間",
    card_days: "日々",
    card_minimum_withdrawal_amount: "最小引き出し金額",
    card_min_prarticion_amount: "最小参加金額",
    card_current_Redeemable_Capital: "現在返済可能資本",
    card_Withdrawn_Commission: "支払い済み手数料",
    card_Withdrawn_Interest: "支払い済み利息",
    card_Withdrawn_Principa: "支払い済み元本",
  },
  cardJS: {
    card_loading: "読み込み中",
    card_input_empty: "入力金額を空にすることはできません",
    card_success: "成功",
  },
  modeDiv: {
    mode_title: "友達を招待する",
    mode_save: "保存",
    mode_copy_success: "コピー成功",
    mode_copy_error: "コピーエラー",
    mode_redem: "償還",
    mode_confirm: "確認",
    mode_input_empty: "入力金額を空にすることはできません",
    mode_loading: "読み込み中...",
    mode_success: "成功",
    mode_failed: "失敗",
    mode_insufficient: "天然ガスの資金が不足しています",
    mode_transaction: "取引に失敗しました",
    mode_insufficient_balance: "残高不足",
    mode_redeem_failed: "償還に失敗しました",
    mode_rules: "ルール",
    mode_inviting:
      "友達を招待してエントリーさせると、友達がマイニングに資金を投入した場合、あなたは10-30％の利息を受け取ります",
    mode_supply: "供給",
    mode_user_denied: "ユーザーが取引を拒否しました",
    mode_wiuhdraw: "引き出し",
    mode_: "友達を招待する",
    mode_wiuhdraw_text: "利子または手数料を引き出します",
    mode_supply_text: "元本を提供します",
    mode_redem_text: "元本を償却します",
    mode_rules_text_1:
      "友達を招待して入場させることによって、友達がマイニングに資金を投入すると、あなたは資金利用の10-30％の利子を得ます。",
    mode_rules_text_2:
      "APDは資金の日次収益です。利回りは資金の利用状況によってわずかに変動するかもしれません。",
    mode_rules_text_3:
      "最低引き出し金額に達したとき、利子または手数料はいつでも引き出せます。",
    mode_rules_text_4:
      "提供する資金は期限に達した後、現在の引き換え可能資本に表示され、引き換えることができます。",
    mode_rules_text_5:
      "プロジェクトの終了は、現在のプロジェクトの締め切りを意味します。プロジェクトの終了は、資金や利子、手数料の引き出しに影響しません。プロジェクトの終了後、新しいプロジェクトがリリースされ、新しいプロジェクトに参加できます。",
    mode_Currentlyavailable: "現在ご利用いただけます",

    mode_biejingTime: "ロンドン時間",
    mode_startSnapshot: "スナップショットを開始",
    mode_go_vAny: '行く vAny'
  },
  swapDiv: {
    swap_title: "スワップ",
    swap_trade: "瞬時にトークンを取引する",
    swap_balance: "残高",
    swap_price: "価格",
    swap_slippage: "スリッページ許容量",
    swap_point: "スライドポイントを選択",
    swap_SWAP: "スワップ",
  },
  swapJS: {
    swap_quantity: "正しい数量を入力してください",
    swap_Successfullyswap: "交換成功しました。",
    swap_failed: "交換に失敗しました。",
    swap_input_amount: "入力金額を空にすることはできません。",
    swap_select_token: "トークンを選択してください。",
    swap_insufficient_balance: "残高が不足しています。",
  },
};
export default ja_JP;
