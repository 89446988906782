const en_YDL = {
  homeDiv: {
    home_title: "Snapshot per Ricevere Airdrops",
    home_snapshot_coin: "Moneta Snapshot",
    home_add_wallet: "Aggiungi al portafoglio",
    home_capital: "Capitale",
    home_participate: "Partecipa",
    home_earnings: "Guadagni",
    home_withdraw: "Preleva",
    home_aggregate_Earnings_Pool: "Pool di Guadagni Aggregati",
    home_you_send: "Tu invii",
    home_you_get: "Tu ricevi",
    home_buy_vANY: "Acquista vANY",
  },
  homeJS: {
    home_snapshot: "Snapshot",
    home_incentives: "Incentivi",
    home_all: "Tutto",
    home_toward: "Verso",
    home_stop: "Ferma",
    home_my: "Il mio",
    home_status: "stato",
    home_token_added: "Il token è stato aggiunto al wallet.",
    home_token_addition: "Aggiunta del token fallita.",
    home_error_adding: "Errore durante l'aggiunta del token al wallet.",
    home_error_adding_tokenYour:
      "Errore nell'aggiunta del token. Il tuo portafoglio potrebbe non supportarlo",
    home_please_install: "Per favore installa il portafoglio",
    home_please_switch_networks: "Per favore cambia rete",
    home_network_error: "Errore di rete, cambia rete e riprova",
    home_stake_loading: "Caricamento della partecipazione",
    home_snapshot_balance:
      "il saldo dello snapshot non è sufficiente per ottenere la ricompensa, almeno:",
    home_you_are_blocked: "Sei bloccato",
    home_loading: "caricamento...",
    home_account_error: "errore dell'account",
    home_please_switch_chains: "Per favore cambia catene",
    home_please_network: "Per favore seleziona la rete corretta",
    home_please_address: "Per favore collega il tuo indirizzo",
    home_wallet_connect_deny: "connessione al portafoglio negata",
    home_old_wallet_version: "vecchia versione del portafoglio",
    home_switch_chain_and_retry: "Cambia catena e riprova",
    home_enter_number_tokens: "Inserisci il numero di token",
    home_please_select_token: "Per favore seleziona un token",
    home_copy_success: "copia riuscita",
    home_copy_error: "errore di copia",
  },
  rulesDiv: {
    rules_title: "Regole e Allocazione degli Interessi",
    rules_text:
      "Invitando i tuoi amici a entrare, se i tuoi amici investono soldi nel mining, sarai ricompensato con il 10 percento degli interessi dei tuoi amici, e questi interessi non influenzeranno",
    rules_text2:
      "Investi denaro per impegnare monete e ottenere interessi, se il cambiamento scende a un certo livello, la piattaforma liquiderà i token",
    rules_invite_friends: "Invita Amici",
    rules_me: "IO",
    rules_layer_users: "Utenti del Livello 1",
    rules_layer_users2: "Utenti del Livello 2",
    rules_layer_users3: "Utenti del Livello 3",
  },
  navMuenDiv: {
    nav_home: "Home",
    nav_rules: "Regole",
    nav_language: "Lingua",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Ora di Fine",
    card_APD: "APD",
    card_interest: "Interesse",
    card_commissions: "Commissioni",
    card_capital: "Capitale Accumulato",
    card_friends_interest: "Interessi degli Amici",
    card_supply: "Offerta",
    card_Resupply: "Resupply",
    card_redem: "Riscatta",
    card_wiuhdraw: "Preleva",
    card_enter_amount: "Inserisci importo",
    card_confirm: "conferma",
    card_duration: "Durata",
    card_days: "giorni",
    card_minimum_withdrawal_amount: "Importo Minimo di Prelievo",
    card_min_prarticion_amount: "Importo Minimo di Partecipazione",
    card_current_Redeemable_Capital: "Capitale Attualmente Rimborsabile",
    card_Withdrawn_Commission: "Commissione Prelevata",
    card_Withdrawn_Interest: "Interessi Prelevati",
    card_Withdrawn_Principa: "Capitale Prelevato",
  },
  cardJS: {
    card_loading: "caricamento",
    card_input_empty: "L'importo inserito non può essere vuoto",
    card_success: "Successo",
  },
  modeDiv: {
    mode_title: "Invita Amici",
    mode_save: "Salva",
    mode_copy_success: "copia riuscita",
    mode_copy_error: "errore di copia",
    mode_redem: "Riscatta",
    mode_confirm: "conferma",
    mode_input_empty: "L'importo inserito non può essere vuoto",
    mode_loading: "Caricamento...",
    mode_success: "successo",
    mode_failed: "fallito",
    mode_insufficient: "Finanziamento insufficiente per il gas naturale",
    mode_transaction: "Transazione fallita",
    mode_insufficient_balance: "Saldo insufficiente",
    mode_redeem_failed: "riscatto fallito",
    mode_rules: "Regole",
    mode_inviting:
      "Invitando i tuoi amici a entrare, se i tuoi amici investono soldi nel mining, sarai ricompensato con il 10-30 percento di interessi",
    mode_supply: "Offerta",
    mode_user_denied: "L'utente ha negato la transazione",
    mode_wiuhdraw: "Preleva",
    mode_: "Invita Amici",
    mode_wiuhdraw_text: "Retirar su interés o comisión",
    mode_supply_text: "Suministrar su principal",
    mode_redem_text: "Redimir su principal",
    mode_rules_text_1:
      "Invitando i tuoi amici a entrare, se i tuoi amici investono denaro nell'estrazione, verrai premiato con un interesse del 10-30 per cento",
    mode_rules_text_2:
      "APD è il rendimento giornaliero sui fondi, che fluttuerà leggermente poiché il rendimento sarà determinato dall'utilizzo dei fondi.",
    mode_rules_text_3:
      "I tuoi interessi o commissioni possono essere prelevati in qualsiasi momento quando si raggiunge l'Importo Minimo di Prelievo.",
    mode_rules_text_4:
      "I fondi che fornisci, dopo aver raggiunto il termine, possono essere mostrati nel Capitale Riscattabile Attuale, e tu puoi Riscattarli.",
    mode_rules_text_5:
      "La fine del progetto significa: la scadenza del progetto attuale, la fine del progetto non influisce sui tuoi fondi o interessi, prelievo di commissioni, la fine del progetto, verrà rilasciato un nuovo progetto, puoi partecipare nuovamente a un nuovo progetto.",
    mode_Currentlyavailable: "Attualmente Disponibile",
    mode_biejingTime: "ora di Londra",
    mode_startSnapshot: "Avvio snapshot",
    mode_go_vAny: 'Andare vAny'
  },
  swapDiv: {
    swap_title: "Scambio",
    swap_trade: "Scambia token istantaneamente",
    swap_balance: "Bilancio",
    swap_price: "Prezzo",
    swap_slippage: "Tolleranza allo slittamento",
    swap_point: "Seleziona punto di scorrimento",
    swap_SWAP: "SCAMBIO",
  },
  swapJS: {
    swap_quantity: "Si prega di inserire la quantità corretta",
    swap_Successfullyswap: "Swap eseguito con successo.",
    swap_failed: "Swap fallito.",
    swap_input_amount: "L'importo inserito non può essere vuoto.",
    swap_select_token: "Per favore, seleziona un token.",
    swap_insufficient_balance: "Il tuo saldo è insufficiente.",
  },
};
export default en_YDL;
