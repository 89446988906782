const ru_RU = {
  homeDiv: {
    home_title: "Снимок для получения аирдропов",
    home_snapshot_coin: "Снимок монеты",
    home_add_wallet: "Добавить в кошелек",
    home_capital: "Капитал",
    home_participate: "Участвовать",
    home_earnings: "Доход",
    home_withdraw: "Вывести",
    home_aggregate_Earnings_Pool: "Агрегированный пул доходов",
    home_you_send: "Вы отправляете",
    home_you_get: "Вы получаете",
    home_buy_vANY: "Купить vANY",
  },
  homeJS: {
    home_snapshot: "Снимок",
    home_incentives: "Стимулы",
    home_all: "Все",
    home_toward: "К",
    home_stop: "Остановить",
    home_my: "Мой",
    home_status: "статус",
    home_token_added: "Токен был добавлен в кошелек.",
    home_token_addition: "Добавление токена не удалось.",
    home_error_adding: "Ошибка при добавлении токена в кошелек.",
    home_error_adding_tokenYour:
      "Ошибка добавления токена. Ваш кошелек может не поддерживать его",
    home_please_install: "Пожалуйста, установите кошелек",
    home_please_switch_networks: "Пожалуйста, смените сеть",
    home_network_error: "Ошибка сети, смените сеть и попробуйте снова",
    home_network_errorthis: "Сеть неправильная. Правильный идентификатор сети:",
    home_network_current: "Текущая сеть:",
    home_stake_loading: "Загрузка ставки",
    home_snapshot_balance:
      "баланс снимка недостаточен для получения награды, по крайней мере:",
    home_you_are_blocked: "Вы заблокированы",
    home_loading: "загрузка...",
    home_account_error: "ошибка аккаунта",
    home_please_switch_chains: "Пожалуйста, смените цепочку",
    home_please_network: "Пожалуйста, выберите правильную сеть",
    home_please_address: "Пожалуйста, подключите ваш адрес",
    home_wallet_connect_deny: "отказано в подключении кошелька",
    home_old_wallet_version: "старая версия кошелька",
    home_switch_chain_and_retry: "Смените цепочку и попробуйте снова",
    home_enter_number_tokens: "Введите количество токенов",
    home_please_select_token: "Пожалуйста, выберите токен",
    home_copy_success: "копирование успешно",
    home_copy_error: "ошибка копирования",
  },
  rulesDiv: {
    rules_title: "Правила и распределение процентов",
    rules_text:
      "Пригласив своих друзей, если ваши друзья вложат деньги в майнинг, вы будете вознаграждены 10 процентами от процентов ваших друзей, и эти проценты не повлияют на",
    rules_text2:
      "Вложите деньги, чтобы заложить монеты и получить проценты, если изменение упадет до определенного уровня, платформа ликвидирует токены",
    rules_invite_friends: "Пригласить друзей",
    rules_me: "Я",
    rules_layer_users: "Пользователи 1 уровня",
    rules_layer_users2: "Пользователи 2 уровня",
    rules_layer_users3: "Пользователи 3 уровня",
  },
  navMuenDiv: {
    nav_home: "Главная",
    nav_rules: "Правила",
    nav_language: "Язык",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Время окончания",
    card_APD: "APD",
    card_interest: "Процент",
    card_commissions: "Комиссии",
    card_capital: "Накопленный капитал ",
    card_friends_interest: "Процент друзей",
    card_supply: "Поставка",
    card_Resupply: "Resupply",
    card_redem: "Выкуп",
    card_wiuhdraw: "Вывести",
    card_enter_amount: "Введите сумму",
    card_confirm: "подтвердить",
    card_duration: "Продолжительность",
    card_days: "дней",
    card_minimum_withdrawal_amount: "Минимальная сумма для снятия",
    card_min_prarticion_amount: "Минимальная сумма участия",
    card_current_Redeemable_Capital: "Текущий возмещаемый капитал",
    card_Withdrawn_Commission: "Удержанная комиссия",
    card_Withdrawn_Interest: "Удержанный процент",
    card_Withdrawn_Principa: "Удержанная сумма",
  },
  cardJS: {
    card_loading: "загрузка",
    card_input_empty: "Сумма ввода не может быть пустой",
    card_success: "Успешно",
  },
  modeDiv: {
    mode_title: "Пригласить друзей",
    mode_save: "Сохранить",
    mode_copy_success: "копирование успешно",
    mode_copy_error: "ошибка копирования",
    mode_redem: "Выкуп",
    mode_confirm: "подтвердить",
    mode_input_empty: "Сумма ввода не может быть пустой",
    mode_loading: "Загрузка...",
    mode_success: "успех",
    mode_failed: "неудача",
    mode_insufficient: "Недостаточно средств для природного газа",
    mode_transaction: "Сделка не удалась",
    mode_insufficient_balance: "Недостаточно средств",
    mode_redeem_failed: "выкуп не удался",
    mode_rules: "Правила",
    mode_inviting:
      "Пригласив своих друзей, если ваши друзья вложат деньги в майнинг, вы будете вознаграждены 10-30 процентами от процентов",
    mode_supply: "Поставка",
    mode_user_denied: "Пользователь отклонил транзакцию",
    mode_wiuhdraw: "Вывести",
    mode_: "Пригласить друзей",
    mode_wiuhdraw_text: "Снять свои проценты или комиссию",
    mode_supply_text: "Предоставить свои средства",
    mode_redem_text: "Вернуть свои средства",
    mode_rules_text_1:
      "Приглашая ваших друзей войти, если ваши друзья вложат деньги в майнинг, вы будете вознаграждены 10-30 процентами процентов",
    mode_rules_text_2:
      "APD - это ежедневный доход от средств, который будет немного колебаться, так как доход будет определяться использованием средств.",
    mode_rules_text_3:
      "Ваши проценты или комиссию можно выводить в любое время, когда достигнут минимальный порог вывода.",
    mode_rules_text_4:
      "Предоставленные вами средства, после достижения срока, могут быть показаны в текущем погашаемом капитале, и вы можете погасить.",
    mode_rules_text_5:
      "Конец проекта означает: дедлайн текущего проекта, конец проекта не влияет на ваши средства или проценты, вывод комиссии, конец проекта, будет выпущен новый проект, вы можете снова участвовать в новом проекте.",
    mode_Currentlyavailable: "В настоящее время доступно",
    mode_biejingTime: "лондонское время",
    mode_startSnapshot: "Начать снимок",
    mode_go_vAny: 'Идти vAny'
  },
  swapDiv: {
    swap_title: "Обмен",
    swap_trade: "Торгуйте токенами мгновенно",
    swap_balance: "Баланс",
    swap_price: "Цена",
    swap_slippage: "Допустимое скольжение",
    swap_point: "Выберите точку скольжения",
    swap_SWAP: "ОБМЕН",
  },
  swapJS: {
    swap_quantity: "Пожалуйста, введите правильное количество",
    swap_Successfullyswap: "Успешный обмен.",
    swap_failed: "Обмен не удался.",
    swap_input_amount: "Введенная сумма не может быть пустой.",
    swap_select_token: "Пожалуйста, выберите токен.",
    swap_insufficient_balance: "Ваш баланс недостаточен.",
  },
};
export default ru_RU;
