const en_PTY = {
  homeDiv: {
    home_title: "Instantâneo Para Receber Airdrops",
    home_snapshot_coin: "Moeda de Instantâneo",
    home_add_wallet: "Adicionar à carteira",
    home_capital: "Capital",
    home_participate: "Participar",
    home_earnings: "Ganhos",
    home_withdraw: "Retirar",
    home_aggregate_Earnings_Pool: "Fundo de Ganhos Agregados",
    home_you_send: "Você envia",
    home_you_get: "Você recebe",
    home_buy_vANY: "Comprar vANY",
  },
  homeJS: {
    home_snapshot: "Instantâneo",
    home_incentives: "Incentivos",
    home_all: "Tudo",
    home_toward: "Em direção a",
    home_stop: "Parar",
    home_my: "Meu",
    home_status: "status",
    home_token_added: "O token foi adicionado à carteira.",
    home_token_addition: "Falha ao adicionar o token.",
    home_error_adding: "Erro ao adicionar o token à carteira.",
    home_error_adding_tokenYour:
      "Erro ao adicionar token. Sua carteira pode não suportá-lo",
    home_please_install: "Por favor, instale a carteira",
    home_please_switch_networks: "Por favor, mude de rede",
    home_network_error: "Erro de rede, mude de rede e tente novamente",
    home_stake_loading: "Carregando stake",
    home_snapshot_balance:
      "saldo do instantâneo não é suficiente para obter recompensa, pelo menos:",
    home_you_are_blocked: "Você está bloqueado",
    home_loading: "carregando...",
    home_account_error: "erro de conta",
    home_please_switch_chains: "Por favor, mude de cadeia",
    home_please_network: "Por favor, selecione a rede correta",
    home_please_address: "Por favor, conecte seu endereço",
    home_wallet_connect_deny: "conexão de carteira negada",
    home_old_wallet_version: "versão antiga da carteira",
    home_switch_chain_and_retry: "Mude de cadeia e tente novamente",
    home_enter_number_tokens: "Digite o número de tokens",
    home_please_select_token: "Por favor, selecione um token",
    home_copy_success: "cópia bem-sucedida",
    home_copy_error: "erro de cópia",
  },
  rulesDiv: {
    rules_title: "Regras e Alocação de Juros",
    rules_text:
      "Ao convidar seus amigos para entrar, se seus amigos colocarem dinheiro na mineração, você será recompensado com 10 por cento de juros dos seus amigos, e esses juros não afetarão",
    rules_text2:
      "Invista dinheiro para empenhar moedas e receber juros, se a mudança cair para um determinado nível, a plataforma liquidará os tokens",
    rules_invite_friends: "Convidar Amigos",
    rules_me: "EU",
    rules_layer_users: "Usuários da Camada 1",
    rules_layer_users2: "Usuários da Camada 2",
    rules_layer_users3: "Usuários da Camada 3",
  },
  navMuenDiv: {
    nav_home: "Início",
    nav_rules: "Regras",
    nav_language: "Idioma",
  },
  cardDiv: {
    card_compound: "Compound V3",
    card_end_time: "Hora de Término",
    card_APD: "APD",
    card_interest: "Juros",
    card_commissions: "Comissões",
    card_capital: " Capital Acumulado",
    card_friends_interest: "Juros dos Amigos",
    card_supply: "Fornecimento",
    card_Resupply: "Resupply",
    card_redem: "Resgatar",
    card_wiuhdraw: "Retirar",
    card_enter_amount: "Digite o valor",
    card_confirm: "confirmar",
    card_duration: "Duração",
    card_days: "dias",
    card_minimum_withdrawal_amount: "Montante Mínimo de Saque",
    card_min_prarticion_amount: "Montante Mínimo de Participação",
    card_current_Redeemable_Capital: "Capital Atualmente Reembolsável",
    card_Withdrawn_Commission: "Comissão Retirada",
    card_Withdrawn_Interest: "Juros Retirados",
    card_Withdrawn_Principa: "Principal Retirado",
  },
  cardJS: {
    card_loading: "carregando",
    card_input_empty: "O valor inserido não pode estar vazio",
    card_success: "Sucesso",
  },
  modeDiv: {
    mode_title: "Convidar Amigos",
    mode_save: "Salvar",
    mode_copy_success: "cópia bem-sucedida",
    mode_copy_error: "erro de cópia",
    mode_redem: "Resgatar",
    mode_confirm: "confirmar",
    mode_input_empty: "O valor inserido não pode estar vazio",
    mode_loading: "Carregando...",
    mode_success: "sucesso",
    mode_failed: "falhou",
    mode_insufficient: "Financiamento insuficiente para gás natural",
    mode_transaction: "Transação falhou",
    mode_insufficient_balance: "Saldo insuficiente",
    mode_redeem_failed: "resgate falhou",
    mode_rules: "Regras",
    mode_inviting:
      "Ao convidar seus amigos para entrar, se seus amigos colocarem dinheiro na mineração, você será recompensado com 10-30 por cento de juros",
    mode_supply: "Fornecimento",
    mode_user_denied: "Usuário negou a transação",
    mode_wiuhdraw: "Retirar",
    mode_: "Convidar Amigos",
    mode_wiuhdraw_text: "Retirar su interés o comisión",
    mode_supply_text: "Suministrar su principal",
    mode_redem_text: "Redimir su principal",
    mode_rules_text_1:
      "Invitando a tus amigos a participar, si tus amigos invierten dinero en minería, se te recompensará con un interés del 10-30 por ciento",
    mode_rules_text_2:
      "APD es el retorno diario sobre los fondos, que fluctuará ligeramente ya que el retorno será determinado por la utilización de los fondos.",
    mode_rules_text_3:
      "Tu interés, o comisión, se puede retirar en cualquier momento cuando se alcance el Monto Mínimo de Retiro.",
    mode_rules_text_4:
      "Los fondos que proporcionas, después de alcanzar el plazo, pueden mostrarse en el Capital Redimible Actual, y puedes Redimirlo.",
    mode_rules_text_5:
      "El fin del proyecto significa: la fecha límite del proyecto actual, el fin del proyecto no afecta a tus fondos o intereses, retiro de comisiones, el fin del proyecto, se lanzará un nuevo proyecto, puedes volver a participar en un nuevo proyecto.",
    mode_Currentlyavailable: "Atualmente Disponível",
    mode_biejingTime: "hora de Londres",
    mode_startSnapshot: "Instantâneo de início",
    mode_go_vAny: 'Ir vAny'
  },
  swapDiv: {
    swap_title: "Trocar",
    swap_trade: "Negocie tokens instantaneamente",
    swap_balance: "Saldo",
    swap_price: "Preço",
    swap_slippage: "Tolerância ao deslize",
    swap_point: "Selecionar ponto de deslize",
    swap_SWAP: "TROCAR",
  },
  swapJS: {
    swap_quantity: "Por favor, insira a quantidade correta",
    swap_Successfullyswap: "Swap efetuado com sucesso.",
    swap_failed: "Swap falhou.",
    swap_input_amount: "O valor de entrada não pode estar vazio.",
    swap_select_token: "Por favor, selecione um token.",
    swap_insufficient_balance: "Seu saldo é insuficiente.",
  },
};
export default en_PTY;
