//英语
const en = {
    homeDiv:{
        home_title:'Snapshot To Receive Airdrops',
        home_snapshot_coin:'Snapshot Coin',
        home_add_wallet:'Add to wallet',
        home_capital:"Capital",
        home_participate:'Participate',
        home_earnings:'Earnings',
        home_withdraw:'Withdraw',
        home_aggregate_Earnings_Pool:'Aggregate Earnings Pool',
        home_you_send:'You send',
        home_you_get:'You get',
        home_buy_vANY:'Trade vAny'
    },
    homeJS:{
        home_snapshot:"Snapshot",
        home_incentives:'Incentives',
        home_all:'All',
        home_toward:'Toward',
        home_stop:'Stop',
        home_my:'My',
        home_status:'status',
        home_token_added:'The token has been added to the wallet',
        home_token_addition:'Token addition failed',
        home_error_adding:'Error adding token to wallet',
        home_error_adding_tokenYour:'Error adding token. Your wallet may not support it',
        home_please_install:'Please install the wallet', 
        home_please_switch_networks:'Please switch networks',
        home_network_error:'Network error, switch network and try again',
        home_network_errorthis:'The network is incorrect The correct network ID is',
        home_network_current:' The current network is',
        home_stake_loading:'Stake loading',
        home_snapshot_balance:'snapshot balance is not enough to get reward,at least:',
        home_you_are_blocked:'You are blocked',
        home_loading:'loading...',
        home_account_error:'account error',
        home_please_switch_chains:'Please switch chains',
        home_please_network:'Please select the correct network',
        home_please_address:'Please connect your address',
        home_wallet_connect_deny:'wallet connect deny',
        home_old_wallet_version:'old wallet version',
        home_switch_chain_and_retry:'Switch chain and retry',
        home_enter_number_tokens:'Enter the number of tokens',
        home_please_select_token:'Please select a token',
        home_copy_success:'copy success',
        home_copy_error:'copy error'
       
    },
    rulesDiv:{
        rules_title:'Referrals and Interest Allocation',
        rules_text:'By inviting your friends to enter, if  your fr- iends put money into mining, you will be rew- arded with 10-30   percent interest from your frie- nds, and this interest will not  affect',
        rules_text2:'Invest money to pledge coins and get inte- rest, if the change falls to a certain level, the platform will liquidate the tokens',
        rules_invite_friends:'Invite Friends',
        rules_me:'ME',
        rules_layer_users:'Layer 1 users',
        rules_layer_users2:'Layer 2 users',
        rules_layer_users3:'Layer 3 users'
    },
    navMuenDiv:{
        nav_home:'Home',
        nav_rules:'Referrals',
        nav_language:'Language'
    },
    cardDiv:{
        card_compound:'Compound V3',
        card_end_time:'End Time',
        card_APD:'APD',
        card_interest:'Interest',
        card_commissions:'Commissions',
        card_capital:'Accumulated Capital',
        card_friends_interest:'Friends Interest',
        card_supply:'Supply',
        card_Resupply:'Resupply',
        card_redem:'Redeem',
        card_wiuhdraw:'Withdraw',
        card_enter_amount:'Enter amount',
        card_confirm:'confirm',
        card_duration:'Duration',
        card_days:'days',
        card_min_prarticion_amount:'Minimum Participation Amount',
        card_minimum_withdrawal_amount:'Minimum Withdrawal Amount',
        card_current_Redeemable_Capital:'Current Redeemable Capital',
        card_Withdrawn_Commission:'Withdrawn Commission',
        card_Withdrawn_Interest:'Withdrawn Interest',
        card_Withdrawn_Principa:'Withdrawn Capital',
    },
    cardJS:{
        card_loading:"loading",
        card_input_empty:'The input amount cannot be empty',
        card_success:'Success'
    },
    modeDiv:{
        mode_title:'Invite Friends',
        mode_save:'Save',
        mode_copy_success:'copy success',
        mode_copy_error:'copy error',
        mode_redem:'Redeem',
        mode_confirm:'confirm',
        mode_input_empty:'The input amount cannot be empty',
        mode_loading:'Loading...',
        mode_success:'success',
        mode_failed:'failed',
        mode_insufficient:'Insufficient funding for natural gas',
        mode_transaction:'Transaction failed',
        mode_insufficient_balance:'Insufficient balance',
        mode_redeem_failed:'redeem failed',
        mode_rules:'Rules',
        mode_inviting:' By inviting your friends to enter, if your frien- ds put money into  mining, you will be reward- ed with 10-30 percent interest',
        mode_supply:'Supply',
        mode_user_denied:'User denied the transaction',
        mode_wiuhdraw:'Withdraw',
        mode_:"Invite Friends",
        mode_wiuhdraw_text:'Withdraw your interest or commission',
        mode_supply_text:"Supply your asset",
        mode_redem_text:"Redeem your asset",
        mode_rules_text_1:"By inviting your friends to enter, if your friends pu t money into mining, you will be rewarded with 10-30 pe rcent interest",
        mode_rules_text_2:"APD is the daily return on funds, which will fluctu ate slightly as the return will be determined by the u tilisation of funds.",
        mode_rules_text_3:"Your interest, or commission, can be Withdraw at any time when the Minimun Withdrawal Amount is re ached.",
        mode_rules_text_4:"The funds you provide, after reaching the term, ca n be shown in Current Redeemable Capital, and you c an Redeem.",
        mode_rules_text_5:"The end of the project means: the deadline of the current project, the end of the project does not affect your funds  or interest, commission withdrawal, the en d of the project, a new project will be released, you c an re-engage in a new project.",
        mode_Currentlyavailable:'Currently Available',
        mode_biejingTime:'London Time',
        mode_startSnapshot:"Start snapshot",
        mode_go_vAny:"Go vAny"
    },
    swapDiv:{
        swap_title:'Swap',
        swap_trade:'Trade tokens in an instant',
        swap_balance:'Balance',
        swap_price:'Price',
        swap_slippage:'Slippage Tolerance',
        swap_point:' Select sliding point',
        swap_SWAP:'SWAP',
        
    },
    swapJS:{
        swap_quantity:"Please enter the correct quantity",
        swap_Successfullyswap:'Successfully swap',
        swap_failed:'swap failed',
        swap_input_amount:'The input amount cannot be empty',
        swap_select_token:'Please select a token',
        swap_insufficient_balance:'Your balance is insufficient'
    },
    invitationDiv:{
        invitation_activity_Time:'Activity Time',
        invitation_days:'Days',
        invitation_content:"Event Content",
        invitation_inVisit:'Visit The Page To Enter',
        invitation_go:'Go',
        invitation_getcode:' Get more rewards, contact customer support for an invitation  code',
        invitation_link:'Link',
        invitation_follow:"Follow ANYSWAP Official",
        invitation_entry:"Follow @ANYSWAP on platform x to unlock an entry!",
        invitation_followed:'Followed',
        invitation_focis:'Focis',
        invitation_username:'Username',
        invitation_myname:'@myname',
        invitation_continue:"Continue",
        invitation_channel:"Join ANYSWAP's Official TG Channel",
        invitation_below: " Click the link below to join ANYSWAP's TG",
        invitation_discord:"Join the official ANYSWAP Discord group",
        invitation_tfficial:' Join The Tfficial ANYSWAP Group',
        invitation_below_discord:"Click the link below to join ANYSWAP's Discord",
        invitation_rewards:'Refer a Friends For Extra Rewards',
        invitation_your_invitation_code:"Your Invitation Code",
        invitation_share_your_link:'Click to share your link',
        invitation_activity_play:"Activity Play",
        invitation_fragmented:"Fragmented : Assets: The ERC404 protocol supports the  fragmentation of assets by splitting"
    }
}
export default en;

