const farmsModel = {
    Redem: () => import("@/components/model/common/my-redem.vue"),
    Exchange: () => import("@/components/model/common/my-supply.vue"),
    Wiuhdraw: () => import("@/components/model/common/my-wiuhdraw.vue"),
    supply: () => import("@/components/model/common/my-supply.vue"),
    Rules: () => import("@/components/model/common/my-rules.vue"),
    ERaise: () => import("@/components/model/common/my-eRaise.vue"),
    Invite: () => import("@/components/model/common/my-Invite.vue"),
    BalanceNot:()=> import("@/components/model/common/my-balanceNot.vue"),
  };
  export default farmsModel;
  